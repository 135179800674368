import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect } from "react"
import { Col, Container } from "react-bootstrap"
import ScrollContainer from "../components/common/Scrolling-container/Scrolling-container"
import Badges from "../components/common/badges/badges"
import Frame from "../components/common/frame"
import Testimonial from "../components/common/testimonial-component/testimonial"
import Navigation from "../components/navigation"
import "../styles/pages/philosophy.scss"
const Philosophy = () => {
  const data = PhilosophyPageQuery()
  const { cards, newfield, images, seo, hero, testimonial } = data
  const responsive = {
    all: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  let checkPoint = 0

  useEffect(() => {
    let vidElement1 = document.getElementById("myVideo1")
    document.addEventListener("scroll", function() {
      let vidElement2 = document.getElementById("myVideo2")
      let vidWrapperRect = document
        .getElementById("vid-wrapper")
        .getBoundingClientRect()
      let navElementRect = document
        .getElementById("drp-nav")
        .getBoundingClientRect()

      if (vidWrapperRect.y - navElementRect.height < -90) {
        vidElement2.style.display = "unset"
        vidElement1.style.display = "none"

        if (checkPoint === 0) {
          vidElement2.currentTime = 0
          vidElement2.play()
          checkPoint += 1
        }
      } else {
        vidElement1.style.display = "unset"
        vidElement2.style.display = "none"
        vidElement2.style.autoPlay = "false"
        checkPoint = 0
      }
    })
  }, [])

  return (
    <div className="philosophy">
      <Frame seo={seo}>
        <header>
          <Navigation page="Home" />
        </header>

        <section
          className="ball-hero mt100 mb100 prelative"
          style={{ minHeight: "740px" }}
        >
          <Container className="Layout-conatiner">
            <Col
              lg={6}
              id="trans-opac"
              className="mx-auto text-center prelative"
              style={{ zIndex: "1" }}
            >
              <h1 className=" fw-bolder">{hero.heroExcerpt}</h1>
              <div className="p18 mt24 text-grey mb38">
                <p>{hero.heroExcerptList[0]}</p>
                <p>{hero.heroExcerptList[1]}</p>
              </div>
              <div className="p24 fw-medium text-deep-purple">
                <p>
                  {hero.tag} <span className="text-pink ">{hero.tagLink}</span>
                </p>
              </div>
            </Col>
          </Container>

          <div
            id="vid-wrapper"
            className="vid-wrapper d-flex justify-content-center d-dsk"
            style={{
              left: "0%",
              top: "-100px",
              position: "absolute",
              width: "100%",
              zIndex: "0",
            }}
          >
            <video
              id="myVideo1"
              preload="auto"
              autoPlay
              loop
              muted
              style={{ maxWidth: "1440px", width: "100%" }}
            >
              <source
                src="https://media.graphassets.com/t9WZp4zvTAaBJgYfWsHG"
                type="video/mp4"
              />
            </video>
            <video
              id="myVideo2"
              preload="auto"
              autoPlay
              muted
              style={{
                maxWidth: "1440px",
                width: "100%",
                display: "none",
              }}
            >
              <source
                src="https://media.graphassets.com/NSwiUU78SKG2p46VggVQ"
                type="video/mp4"
              />
            </video>
          </div>
        </section>

        <section className="section-container-scroll">
          <ScrollContainer
            data={cards}
            colors={hero.animAltText}
            topValue="260"
            showIndexNum
            leftColumn={5}
            rightColumn={5}
            justifyEvenly
          />
        </section>

        <div className="badge-testimonial mx-auto">
          <section className="badge-section">
            <Badges images={images} />
          </section>

          <section className="testimonial-section">
            <Testimonial type="customer-quote" testimonial={testimonial} />
          </section>
        </div>
      </Frame>
    </div>
  )
}

export default Philosophy

export function PhilosophyPageQuery() {
  const returneddata = useStaticQuery(graphql`
    query philoy {
      SuperOps {
        pageComponents(where: { pageName: "philosophy" }) {
          seo {
            title
            description
            image {
              url
            }
          }
          hero {
            heroExcerpt
            heroExcerptList
            tag
            tagLink
            animAltText
          }
          testimonial {
            testimonialDatas{
              heading
              description
              title
              name
              content
              image{
                url
              }
            }
            testimonialTitle
            testimonialName
            testimonialDescription
            testimonialContent
            testimonialImage {
              url
            }
          }
          cards {
            ... on SuperOps_Card {
              heading
              title
              slug
              subtext
              links
              image {
                url
              }
            }
          }
          images {
            url
          }
        }
      }
    }
  `)

  return returneddata.SuperOps.pageComponents[0]
}
